<template>
  <div
    class="w-full h-full p-12 overflow-hidden flex flex-col items-center justify-center"
    style="background: #0f1f2b"
  >
    <img :src="logo" width="173" alt="tillhub-logo" style="padding-top: 37px" />
    <span class="title browser-base-text text-th-secondary pt-20">{{
      $t('pages.error.browser.title')
    }}</span>
    <span class="subtitle browser-base-text text-white pt-10">{{
      $t('pages.error.browser.subtitle')
    }}</span>
    <img
      :src="flippedGhostImage"
      width="450"
      alt="unsupported-browser"
      class="pt-24"
    />
  </div>
</template>

<script>
import flippedGhostImage from '@/assets/illustrations/flipped-ghost.svg'
import { useWhitelabelThemeStore } from '@/store/whitelabel-theme'
const DEFAULT_LOGO =
  'https://storage.googleapis.com/tillhub-api-images/dashboard/logos/tillhub-logo-white%403x.png'

export default {
  name: 'Browser',
  setup() {
    return { themeStore: useWhitelabelThemeStore() }
  },
  data() {
    return {
      flippedGhostImage
    }
  },
  computed: {
    logo() {
      return (
        this.themeStore.customWelcomeLogo ||
        this.themeStore.customLogo ||
        DEFAULT_LOGO
      )
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 36px;
  letter-spacing: 0.16px;
}

.subtitle {
  font-size: 20px;
}

.browser-base-text {
  font-family: var(--font-face);
  font-style: var(--child-item-style);
  font-weight: 600;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
</style>
